import { Button } from "react-bootstrap";

export default function Baloise() {
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      background: "transparent",
      height: "85vh",
    },
  };

  return (
    <>
      <div className="d-grid gap-2 d-md-flex justify-content-md-center">
        <Button
          className=" mt-0 mb-4"
          variant="success"
          href="https://monitor.earlybyte.ch/status/bat"
          target="_blank"
        >
          Status Seite BAT
        </Button>
        <Button
          className="mt-0 mb-4"
          variant="secondary"
          href="https://forms.clickup.com/4519779/f/49xv3-4727/UDL7HKOPV18PVYE3ZH"
          target="_blank"
        >
          Formular direkt öffnen
        </Button>
      </div>
      <iframe
        title="Baloise Support Form"
        className="clickup-embed clickup-dynamic-height"
        src="https://forms.clickup.com/4519779/f/49xv3-4727/UDL7HKOPV18PVYE3ZH"
        width="100%"
        height="100%"
        style={styles.container}
        onWheel={() => {}}
      ></iframe>
      <script
        async
        src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
      ></script>
    </>
  );
}
