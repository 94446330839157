import { Outlet } from "react-router-dom";
import logo from "../Logo-Only-Gradient-Original100.png";

export default function Root() {
  return (
    <>
      <div className="col-lg-8 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <a
            href="https://www.earlybyte.ch"
            className="d-flex align-items-center text-dark text-decoration-none"
          >
            <img src={logo} alt={"Earlybyte Logo"} height={42} />
            <span className="fs-4">Support Earlybyte GmbH</span>
          </a>
        </header>
        <main>
          <Outlet />
        </main>
        <footer className="pt-5 my-5 text-muted border-top">
          Earlybyte GmbH &middot; &copy; {new Date().getFullYear()}
        </footer>
      </div>
    </>
  );
}
